@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.pivotal-header {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
  padding: 1.5rem;
}

input {
  padding: 0.5rem;
  border-radius: 0.25rem;
  outline: none;
  border: 1px solid #ccc;
  width: 15rem;
}
/* style={{
  outline: "none",
  color: "white",
  border: "none",
  borderRadius: "0.25rem",
  cursor: "pointer",
  width: "5%",
  backgroundColor: "#f44336",
  height: "50px",
  padding: "0.2rem 0"
}} */
button {
  outline: none;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 1rem;
}